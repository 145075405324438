import React, { useState, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import {
  singleDepositTokenStart,
  cancelDepositTokenStart
} from "../../store/slices/SubscriptionSlice";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const DepositBankAccount = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("deposit_transaction");
  const [skipRender, setSkipRender] = useState(true);

  const singleDepositToken = useSelector((state) => state.subscription.singleDepositToken);
  const cancelDepositToken = useSelector((state) => state.subscription.cancelDepositToken);

  useEffect(() => {
    dispatch(
      singleDepositTokenStart({
        user_deposit_id: params.user_deposit_id,
      })
    );
  }, []);

  useEffect(() => {
    if (!skipRender && !cancelDepositToken.loading && Object.keys(cancelDepositToken.data).length > 0)
      navigate("/user-deposits");
    setSkipRender(false);
  }, [cancelDepositToken]);

  return (
    <>
      <div className=" bank-detail-container">
        {singleDepositToken.loading ? (
          <div className="bank-detail h-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 bg-white w-3/4 rounded-lg shadow-lg mt-3 mb-3">
            <div className="bank-detail-card">
              <div className="heading">
                <Skeleton count={1} className="mb-3" height={32} width={300} />
                <Skeleton count={2} className="mb-3" height={20} width={400} />
              </div>
              <Skeleton count={1} height={18} width={130} />
              <Skeleton count={2} className="mb-3" height={20} width={400} />
              <Skeleton count={1} className="mb-3" height={40} width={450} />
              <Skeleton count={7} className="mb-3" height={35} width={200} />
            </div>
            <div className="deposite-card-img mb-3">
              <Skeleton count={1} height={450} width={460} />
            </div>
          </div>
        ) : Object.keys(singleDepositToken.data).length > 0 ? (
          <div className="bank-detail grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 bg-white  w-3/4 rounded-lg shadow-lg">
            <div className="bank-detail-card">
              <div className="heading">
                <h2 className="text-2xl font-bold text-slate-800 dark:text-navy-50 lg:text-2xl mb-3">
                  {t("heading")}
                </h2>
                <p className="mb-8">{t("all_transactions_para")}</p>
              </div>
              <h5 className="text-primary">
                <b>{t("verification_code")}:</b>
              </h5>
              <p>{t("verification_code_para")}</p>
              <div className="histroy-id-frame mb-3">
                {singleDepositToken.data.payment.verification_code}
                <CopyToClipboard
                  text={singleDepositToken.data.payment.verification_code}
                  onCopy={() =>
                    getSuccessNotificationMessage(t("verification_code_copied"))
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 24 24"
                    className="swap-connect-icon"
                  >
                    <path
                      fill="#e47c3f"
                      d="M18 5.086L12.914 0H5a3 3 0 00-3 3v17h16zM4 18V3a1 1 0 011-1h7v4h4v12zm18-9v15H7v-2h13V7z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </CopyToClipboard>
              </div>
              <p className="mt-2">{t("beneficiary_name")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {
                  singleDepositToken.data.payment.billing_account
                    .account_holder_name
                }
              </h4>

              <p>{t("amount")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {singleDepositToken.data.payment.amount}
              </h4>

              <p>{t("account_number")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {singleDepositToken.data.payment.billing_account.account_number}
              </h4>

              <p>{t("bank_name")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {singleDepositToken.data.payment.billing_account.bank_name}
              </h4>

              <p>{t("branch_name")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {singleDepositToken.data.payment.billing_account.branch_name}
              </h4>

              <p>{t("branch_code")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {singleDepositToken.data.payment.billing_account.branch_code}
              </h4>
              <p>{t("bank_type")}</p>
              {singleDepositToken.data.payment.billing_account.bank_type == "Savings" ?
                <h4 className="text-slate-800 text-md font-semibold">
                  {t("savings")}
                </h4>
                :
                <h4 className="text-slate-800 text-md font-semibold">
                  {t("current")}
                </h4>
              }
              <p>{t("bank_address")}</p>
              <h4 className="text-slate-800 text-md font-semibold">
                {singleDepositToken.data.payment.billing_account.bank_address}{" "}
              </h4>
              <div className="request-payout-payment-method-btn-sec">
                <Button
                  className="efi-cancel-btn"
                  disabled={cancelDepositToken.buttonDisable}
                  onClick={() => dispatch(cancelDepositTokenStart({
                    user_deposit_id: singleDepositToken.data.payment.id
                  }))}
                >
                  {cancelDepositToken.buttonDisable ? t("loading") : t("cancel")}
                </Button>
                <Button
                  onClick={() => navigate("/user-deposits")}
                  type="button"
                  className="request-payment-btn btn py-3 px-5 bg-primary text-white hover:bg-primary-focus w-full h-10 focus:bg-primary-focus active:bg-primary-focus/90"
                >
                  {t("done")}
                </Button>
              </div>
            </div>
            <div className="deposite-card-img money-deposite-img-sec">
              <Image
                src={window.location.origin + "/images/deposit-bank-new.png"}
                className="buy-processing-img"
              />{" "}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DepositBankAccount;
