import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  changePasswordFailure,
  changePasswordSuccess,
  dashboardFailure,
  dashboardSuccess,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  generateAPIKeyFailure,
  generateAPIKeySuccess,
  loginFailure,
  loginSuccess,
  profileFailure,
  profileSuccess,
  registerFailure,
  registerSuccess,
  resetPasswordFailure,
  resetPasswordSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  viewAPIKeyFailure,
  viewAPIKeySuccess,
  kycApplicantFailure,
  kycApplicantSuccess,
  kycStatusUpdateFailure,
  kycStatusUpdateSuccess
} from "../slices/AdminSlice";


function* loginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    successCallback: true,
    success: data => {
      localStorage.setItem("userId", data.user.user_id);
      localStorage.setItem("token", data.access_token)
      if (action.payload.remember){
      localStorage.setItem("token", data.access_token)
      }else sessionStorage.setItem("token", data.access_token);
      store.dispatch(loginSuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: loginFailure,
  });
}

function* registerAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    successCallback: true,
    success: data => {
      localStorage.setItem("userId", data.user.user_id);
      localStorage.setItem("token", data.access_token)
      if (action.payload.remember) localStorage.setItem("token", data.access_token);
      else sessionStorage.setItem("token", data.access_token);
      store.dispatch(registerSuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: registerFailure,
  });
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    success: forgotPasswordSuccess,
    failure: forgotPasswordFailure,
  });
}

function* resetPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.payload,
    success: resetPasswordSuccess,
    failure: resetPasswordFailure,
  });
}

function* profileAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => store.dispatch(profileSuccess(data.user)),
    failure: profileFailure,
  });
}

function* updateProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(updateProfileSuccess(data));
    },
    failure: updateProfileFailure,
  });
}

function* dashboardAPI(action) {
  yield getCommonSaga({
    apiUrl: "dashboard",
    payload: action.payload,
    successNotify: false,
    success: dashboardSuccess,
    failure: dashboardFailure,
  });
}

function* generateAPIKeyAPI(action) {
  yield getCommonSaga({
    apiUrl: "generate_api_key",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(generateAPIKeySuccess(data));
      store.dispatch(viewAPIKeySuccess(data));
    },
    failure: generateAPIKeyFailure,
  });
}

function* viewAPIKeyAPI(action) {
  yield getCommonSaga({
    apiUrl: "view_api_key",
    payload: action.payload,
    successNotify: false,
    success: viewAPIKeySuccess,
    failure: viewAPIKeyFailure,
  });
}

function* kycApplicantAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_create_applicant",
    payload: action.payload,
    successNotify: false,
    success: kycApplicantSuccess,
    failure: kycApplicantFailure,
  });
}

function* kycStatusUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_status_update",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
      store.dispatch(kycStatusUpdateSuccess(data));
    },
    failure: kycStatusUpdateFailure,
  });
}

function* changePasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "change_password",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(changePasswordSuccess({ data: data })),
    failure: changePasswordFailure,
  });
}


export default function* AdminSaga() {
  yield all([
    yield takeLatest('admin/loginStart', loginAPI),
    yield takeLatest('admin/registerStart', registerAPI),
    yield takeLatest('admin/forgotPasswordStart', forgotPasswordAPI),
    yield takeLatest('admin/resetPasswordStart', resetPasswordAPI),
    yield takeLatest('admin/profileStart', profileAPI),
    yield takeLatest('admin/updateProfileStart', updateProfileAPI),
    yield takeLatest('admin/dashboardStart', dashboardAPI),
    yield takeLatest('admin/generateAPIKeyStart', generateAPIKeyAPI),
    yield takeLatest('admin/viewAPIKeyStart', viewAPIKeyAPI),
    yield takeLatest('admin/kycApplicantStart', kycApplicantAPI),
    yield takeLatest('admin/kycStatusUpdateStart', kycStatusUpdateAPI),
    yield takeLatest('admin/changePasswordStart', changePasswordAPI),
  ]);
}

