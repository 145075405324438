import React, { useState, useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import Helmet from "react-helmet";
import configuration from "react-global-configuration";
import Select from "react-select";
import { setLanguage } from 'react-multi-lang';


const LoginIndex = () => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const login = useSelector((state) => state.admin.login);

  const handleChange = (selectedOption) => {
    setLanguage(selectedOption.value)
    localStorage.setItem('lang', selectedOption.value);
  };

  const options = [
    { value: "en", label: t("en") },
    { value: "jpy", label: t("jpy") },
  ];

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleLogin = (values) => {
    dispatch(loginStart(values));
  };

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "rgb(255 255 255 / 0%)!important",
      border: "1px solid #ffffff47!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#fff",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "800",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#fff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#fff",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };

  return (
    <>
      <Helmet>
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-4bw+/aepP/YC94hEpVNVgiZdgIC5+VKNBQNGCHeKRQN+PtmoHDEXuppvnDJzQIu9"
          crossorigin="anonymous"
        />
        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js"
          integrity="sha384-HwwvtgBNo3bZJJLYd8oVXjrBZt8cqVSpeBNS5n7C8IVInixGAoxmnlMuBnhbgrkm"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="efi-auth-sec">
        <div className="container">
          <div className="header-logo">
            <div className="efi-logo-sec">

              <a href="https://efimarkets.com">
                <img
                  className="efi-login"
                  src={configuration.get("configData.site_logo")}
                  alt="logo"
                />
              </a>
            </div>
            <Select
              options={options}
              styles={customStyles}
              isSearchable={false}
              value={options.find(opt => opt.value === localStorage.getItem("lang"))}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="efi-auth-item">
          <div className="container">
            <div className="row justify-content-md-center">
              {/* <div className="col-md-7 resp-marg-btm-xs">
                <img className="auth-left-side-img" src={window.location.origin + "/images/auth-left-side-img.png"} alt="image" />
              </div> */}
              <div className="col-md-5">
                <div className="efi-auth-box">
                  <div className="efi-auth-title-sec">{t("message")}</div>
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                      remember: false,
                    }}
                    validationSchema={loginSchema}
                    onSubmit={handleLogin}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className="efi-auth-form">
                        <div className="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {t("email.label")}
                          </label>
                          <Field
                            class="form-control"
                            placeholder={t("email.placeholder")}
                            type="email"
                            autoFocus={true}
                            name="email"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </div>
                        <div className="mb-3">
                          <label for="exampleInputEmail1" class="form-label">
                            {t("password.label")}
                          </label>
                          <Field
                            className="form-control"
                            placeholder={t("password.placeholder")}
                            type="password"
                            name="password"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </div>
                        <div className="forgot-password-link-flex">
                          {/* <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={values.remember}
                              onChange={() =>
                                setFieldValue("remember", !values.remember)
                              }
                              id="flexCheckDefault"
                            />
                            <label
                              class="form-check-label"
                              for="flexCheckDefault"
                            >
                              {t("remember")}
                            </label>
                          </div> */}
                          <Link
                            to="/forgot-password"
                            className="forgot-password-link"
                          >
                            {t("forgot")}
                          </Link>
                        </div>
                        <div className="efi-ath-btn-sec">
                          <button
                            className="efi-auth-btn"
                            disabled={login.buttonDisable}
                          >
                            {!login.buttonDisable && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                              </svg>
                            )}
                            {login.buttonDisable ? (
                              <ButtonLoader varient="black" />
                            ) : (
                              t("login_btn.text")
                            )}
                          </button>
                        </div>
                      </FORM>
                    )}
                  </Formik>
                  <div className="efi-footer-link-sec">
                    <span>{t("create_account")}</span>
                    <Link to="/register">{t("register")}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginIndex;
