import axios from "axios";

export const apiUrl = "https://cms-efiapm.rare-able.com/api/user/"; // Production Mode


const Environment = {
    postMethod: async (action, object, baseUrl = apiUrl) => {
        const url = baseUrl + action;

        let formData = new FormData();

        // By Default Id and token



        for (var key in object)
            formData.append(key, object[key]);
        const userId =
            localStorage.getItem("userId") !== "" &&
                localStorage.getItem("userId") !== null &&
                localStorage.getItem("userId") !== undefined
                ? localStorage.getItem("userId")
                : "";

                const lang =
                localStorage.getItem("lang") !== "" &&
                    localStorage.getItem("lang") !== null &&
                    localStorage.getItem("lang") !== undefined
                    ? localStorage.getItem("lang")
                    : "";

        const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
        formData.append("id", userId);
        formData.append("token", accessToken);
        formData.append("lang", lang);
        let config = { headers: { Authorization: 'Bearer ' + accessToken } };
        return await axios.post(url, formData, config);
    },
};

export default Environment;
