import React, { useState, useEffect } from 'react';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Button, Form, Image } from "react-bootstrap";
import * as Yup from "yup";
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { depositTokenStart } from '../../store/slices/SubscriptionSlice';

const DepositAmount = (props) => {

  const t = useTranslation("deposit_amount");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skipRender, setSkipRender] = useState(true);
  const depositToken = useSelector((state) => state.subscription.depositToken);

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .test(t("amount.invalid"), (value) => {
        if (!value) return true;
        else {
          return !isNaN(value);
        }
      })
      .required(t("amount.required"))
      .min(1, t("amount.invalid"))

  });

  const handleSubmit = (values) => {
    dispatch(depositTokenStart({
      ...values,
      from_currency: "JPY"
    }))
  };

  useEffect(() => {
    if (!skipRender && !depositToken.loading && Object.keys(depositToken.data).length > 0) {
      navigate(`/deposit-transaction/${depositToken.data.user_deposit.id}`);
    }
    setSkipRender(false);
  }, [depositToken]);

  function handleKeyPress(event) {
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <>
      <div className='amount-deposit-container'>
        <div className="money-deposit shadow-lg grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2  w-3/4">
          <div className='money-deposite-img-sec'>
            <div className='money-deposite-text'>
              <h3>{t("deposit")}</h3>
              <p>{t("message")}</p>
            </div>
            <Image
              src={window.location.origin + "/images/money-deposit-new.png"}
              className="money-deposite-img"
            />
          </div>
          <div className="money-deposite-card flex items-center justify-center bg-white  rounded-lg ">
            <Formik
              initialValues={{
                amount: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FORM className="exchange-form">
                  <div className="text-center">
                    <Form.Group controlId="formBasicEmail" className="mb-3 text-center">
                      <Form.Label className='text-gray-600'>{t("amount.label")} (¥)</Form.Label>
                      <Field
                        type="number"
                        name="amount"
                        placeholder={t("amount.placeholder")}
                        onKeyPress={handleKeyPress}
                        onPaste={(event) => {
                          event.preventDefault();
                        }}
                        className="amount-text form-control text-xl  text-slate-801 dark:text-navy-50 lg:text-2xl mt-3"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="amount"
                        className="amount-field-required mt-2"
                      />
                    </Form.Group>
                    <div className='text-center'>
                      <Button
                        disabled={depositToken.buttonDisable}
                        type="submit"
                        className="btn py-3 px-5 mt-6 bg-primary  text-white hover:bg-primary-focus w-40 h-10 focus:bg-primary-focus active:bg-primary-focus/90"
                      >
                        {depositToken.buttonDisable ? t("loading") : t("deposit")}
                      </Button>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}

export default DepositAmount;