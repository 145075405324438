import React, { useEffect } from "react";
import { dashboardStart } from "../../store/slices/AdminSlice";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const DashboardIndex = () => {
  const t = useTranslation("dashboard");
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state.admin.dashboard);

  useEffect(() => {
    dispatch(dashboardStart());
  }, []);

  return (
    <>
      <div class="flex items-center space-x-4 py-5 lg:py-6">
        <h2 class="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
       {t("heading")}
        </h2>
      </div>
      {dashboard.loading ? (
        <div class="grid grid-cols-1 gap-6 transition-all duration-[.25s] sm:mt-5 sm:grid-cols-1 sm:gap-5 lg:mt-6 lg:grid-cols-6 lg:gap-4">
          <div class="card p-4 sm:col-span-2 sm:p-5">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-5 lg:gap-6">
              {[...Array(8)].map((i, key) => (
                <Skeleton key={key} height={80} />
              ))}
            </div>
          </div>
        </div>
      ) : Object.keys(dashboard.data).length > 0 ? (
        <div class="grid grid-cols-1 gap-6 transition-all duration-[.25s] sm:mt-5 sm:grid-cols-1 sm:gap-5 lg:mt-6 lg:grid-cols-6 lg:gap-4">
          <div class="card p-4 sm:col-span-2 sm:p-5">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-5 lg:gap-6">
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-info/10 dark:bg-info">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.total_deposits}
                    </p>
                    <p>{t("total_deposits")}</p>
                  </div>
                </div>
              </Link>
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-success/10 dark:bg-success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 7.5 3 4.5m0 0 3-4.5M12 12v5.25M15 12H9m6 3H9m12-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.today_deposits}
                    </p>
                    <p>{t("today_deposits")}</p>
                  </div>
                </div>
              </Link>
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-success/10 dark:bg-success">
                    <i
                      class="fa fa-money-bill fa-lg text-success"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.initiated_deposits}
                    </p>
                    <p>{t("initiated_deposits")}</p>
                  </div>
                </div>
              </Link>
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-info/10 dark:bg-info">
                    <i
                      class="fa fa-money-bill-1-wave fa-lg text-info"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.verified_deposits}
                    </p>
                    <p>{t("verified_deposits")}</p>
                  </div>
                </div>
              </Link>
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-error/10 dark:bg-error">
                    <i
                      class="fa fa-money-bill-trend-up fa-lg text-error"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.declined_deposits}
                    </p>
                    <p>{t("declined_deposits")}</p>
                  </div>
                </div>
              </Link>
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-warning/10 dark:bg-warning">
                    <i
                      class="fa fa-money-check-dollar fa-lg text-warning"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.expired_deposits}
                    </p>
                    <p>{t("expired_deposits")}</p>
                  </div>
                </div>
              </Link>
              <Link to="/user-deposits">
                <div class="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                  <div class="mask is-star-2 flex h-12 w-12 items-center justify-center bg-error/10 dark:bg-error">
                    <i
                      class="fa fa-money-check-dollar fa-lg text-error"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div class="font-inter">
                    <p class="text-base font-semibold text-slate-700 dark:text-navy-100">
                      {dashboard.data.canceled_deposits}
                    </p>
                    <p>{t("canceled_deposits")}</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DashboardIndex;
