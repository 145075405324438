import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  depositTokenSuccess,
  depositTokenFailure,
  tokenDepositListSuccess,
  tokenDepositListFailure,
  singleDepositTokenSuccess,
  singleDepositTokenFailure,
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  fetchAllTransactionSuccess,
  fetchAllTransactionFailure,
  fetchSingleWalletViewSuccess,
  fetchSingleWalletViewFailure,
  cancelDepositTokenSuccess,
  cancelDepositTokenFailure,
  userDepositsExportSuccess,
  userDepositsExportFailure,
  userWalletsExportStart,
  userWalletsExportSuccess,
  userWalletsExportFailure,
} from "../slices/SubscriptionSlice";


function* depositTokenAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_deposits/send_request",
    payload: action.payload,
    success: depositTokenSuccess,
    failure: depositTokenFailure,
  });
}

function* tokenDepositListAPI(action) {
  const tokenDepositListData = yield select(state => state.subscription.tokenDepositList.data)
  yield getCommonSaga({
    apiUrl: "user_deposits/list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(tokenDepositListSuccess({
        ...data,
        payments: [...tokenDepositListData.payments, ...data.payments],
        total: data.total,
      }))
    },
    failure: tokenDepositListFailure,
  });
}

function* singleDepositTokenAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_deposits/view",
    payload: action.payload,
    successNotify: false,
    success: singleDepositTokenSuccess,
    failure: singleDepositTokenFailure,
  });
}

function* fetchWalletDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallets/view",
    payload: action.payload,
    successNotify: false,
    success: fetchWalletDetailsSuccess,
    failure: fetchWalletDetailsFailure,
  });
}

function* fetchAllTransactionAPI(action) {
  const tokenDepositListData = yield select(state => state.subscription.allTransaction.data)
  yield getCommonSaga({
    apiUrl: "wallet_payments",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(fetchAllTransactionSuccess({
        ...data,
        user_wallet_payments: [...tokenDepositListData.user_wallet_payments, ...data.user_wallet_payments],
        total_payments: data.total_payments,
      }))
    },
    failure: fetchAllTransactionFailure,
  });
}

function* fetchSingleWalletViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_payments/view",
    payload: action.payload,
    successNotify: false,
    success: fetchSingleWalletViewSuccess,
    failure: fetchSingleWalletViewFailure,
  });
}

function* cancelDepositTokenViewAPI(action) {
  const tokenDepositListData = yield select(state => state.subscription.tokenDepositList.data);
  yield getCommonSaga({
    apiUrl: "user_deposits/cancel",
    payload: action.payload,
    successCallback: true,
    successNotify: false,
    success: data => {
      store.dispatch(cancelDepositTokenSuccess(data))
      if (Object.keys(tokenDepositListData).length > 0) {
        store.dispatch(tokenDepositListSuccess({
          ...tokenDepositListData,
          payments: tokenDepositListData.payments.map((payment) => payment.id === action.payload.user_deposit_id ?
            data.payment
            : payment,
          )
        }))
      }
    },
    failure: cancelDepositTokenFailure,
  });
}

function* userDepositsExpotAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_deposits/export",
    payload: action.payload,
    successNotify: false,
    success: userDepositsExportSuccess,
    failure: userDepositsExportFailure,
  });
}

function* userWalletsExpotAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_payments/export",
    payload: action.payload,
    successNotify: false,
    success: userWalletsExportSuccess,
    failure: userWalletsExportFailure,
  });
}


export default function* SubscriptionSaga() {
  yield all([
    yield takeLatest('subscription/depositTokenStart', depositTokenAPI),
    yield takeLatest('subscription/tokenDepositListStart', tokenDepositListAPI),
    yield takeLatest('subscription/moreTokenDepositListStart', tokenDepositListAPI),
    yield takeLatest('subscription/singleDepositTokenStart', singleDepositTokenAPI),
    yield takeLatest('subscription/fetchWalletDetailsStart', fetchWalletDetailsAPI),
    yield takeLatest('subscription/fetchAllTransactionStart', fetchAllTransactionAPI),
    yield takeLatest('subscription/fetchMoreTransactionStart', fetchAllTransactionAPI),
    yield takeLatest('subscription/fetchSingleWalletViewStart', fetchSingleWalletViewAPI),
    yield takeLatest('subscription/cancelDepositTokenStart', cancelDepositTokenViewAPI),
    yield takeLatest('subscription/userDepositsExportStart', userDepositsExpotAPI),
    yield takeLatest('subscription/userWalletsExportStart', userWalletsExpotAPI),
  ]);
}


