import AdminSlice from "./AdminSlice";
import CategorySlice from "./CategorySlice";
import PaymentSlice from "./PaymentSlice";
import SubscriptionSlice from "./SubscriptionSlice";

export const reducers = {
    admin: AdminSlice,
    category: CategorySlice,
    payment: PaymentSlice,
    subscription:SubscriptionSlice,
};