import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Helper/Pagination";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { Table } from "react-bootstrap";
import {
  fetchAllTransactionStart,
  fetchWalletDetailsStart,
  userWalletsExportStart
} from "../../store/slices/SubscriptionSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);


const ViewWallet = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("view_wallet");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [skipRender, setSkipRender] = useState(true);
  const wallet = useSelector((state) => state.subscription.walletData);
  const transaction = useSelector((state) => state.subscription.allTransaction);
  const userWalletsExport = useSelector((state) => state.subscription.userWalletsExport);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [error, setError] = useState(false);
  const [filterData, setFilterData] = useState({
    from_date: "",
    to_date: "",
    search_key: "",
    // status: "",
  });

  // const options = [
  //   { value: "0", label: t("processing") },
  //   { value: "1", label: t("success") },
  //   { value: "2 ", label: t("failed") },
  // ];

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0.5rem",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "0.5rem!important",
      boxShadow: "none!important",
      height: "40px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  useEffect(() => {
    dispatch(fetchWalletDetailsStart({}));
    dispatch(
      fetchAllTransactionStart({
        ...filterData,
        skip: 12 * (page - 1),
        take: 12,
      })
    );
  }, [page]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    navigate(`/user-wallet?page=${selected + 1}`);
  };

  const handleSubmit = () => {
    dispatch(
      fetchAllTransactionStart({
        ...filterData,
        skip: 0,
        take: 12,
      })
    );
  };

  const handleToDateChange = (date) => {
    if (date && filterData.from_date && dayjs(date).isBefore(filterData.from_date, 'day')) {
      setError(t("from_date_val_error"));
    } else {
      setError('');
    }
  };

  const exportTransctions = () => {
    dispatch(userWalletsExportStart(filterData));
  }

  useEffect(() => {
    if (!skipRender && !userWalletsExport.loading &&
      Object.keys(userWalletsExport.data).length > 0)
      window.open(userWalletsExport.data.url, '_blank');
    setSkipRender(false);
  }, [userWalletsExport]);

  const handleFromDateChange = (date) => {
    if (date && filterData.to_date &&
      dayjs(date).isAfter(filterData.to_date, 'day')) {
      setError(t("from_date_val_error"));
    } else {
      setError('');
    }
  };

  return (
    <>
      <div className="flex items-center justify-between py-5 lg:py-6">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
            {" "}
            {t("heading")}{" "}
          </h2>
          <div className="hidden h-full py-1 sm:flex">
            <div className="h-full w-px bg-slate-300 dark:bg-navy-600">
              &nbsp;
            </div>
          </div>
          <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
            <li className="flex items-center space-x-2">
              <Link
                to="/user-wallet"
                className="text-primary transition-colors hover:text-primary-focus dark:text-accent-light dark:hover:text-accent"
              >
                {t("heading")}{" "}
              </Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </li>
            <li>{t("view_wallets")} </li>
          </ul>
        </div>
      </div>
      <div className="card px-4 pb-4 sm:px-5">
        <div>
          <h2 className="pb-4 pt-4 text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
            {t("view_user_wallets")}
          </h2>
        </div>
        <div className="pb-4">
          {wallet.loading ? (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-4 lg:gap-4">
              {[...Array(4)].map((i, key) => (
                <Skeleton key={key} height={80} />
              ))}
            </div>
          ) : Object.keys(wallet.data).length > 0 ? (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 sm:gap-4 lg:gap-4">
              <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-secondary/10 dark:bg-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 text-secondary h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="font-inter">
                  <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                    {wallet.data.user_wallet.remaining_formatted}
                  </p>
                  <p>{t("balance")}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-info/10 dark:bg-info">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 text-info h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.5 6.375c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v3.026a.75.75 0 01-.375.65 2.249 2.249 0 000 3.898.75.75 0 01.375.65v3.026c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 17.625v-3.026a.75.75 0 01.374-.65 2.249 2.249 0 000-3.898.75.75 0 01-.374-.65V6.375zm15-1.125a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V6a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0v.75a.75.75 0 001.5 0v-.75zm-.75 3a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0v-.75a.75.75 0 01.75-.75zm.75 4.5a.75.75 0 00-1.5 0V18a.75.75 0 001.5 0v-.75zM6 12a.75.75 0 01.75-.75H12a.75.75 0 010 1.5H6.75A.75.75 0 016 12zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="font-inter">
                  <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                    {wallet.data.user_wallet.used_formatted}
                  </p>
                  <p>
                    {t("used")} {t("balance")}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-primary/10 dark:bg-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 text-primary h-6"
                  >
                    <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                  </svg>
                </div>
                <div className="font-inter">
                  <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                    {wallet.data.user_wallet.total_formatted}
                  </p>
                  <p>{t("total")}</p>
                </div>
              </div>
              <div className="flex items-center space-x-4 rounded-2xl border border-slate-150 p-4 dark:border-navy-600">
                <div className="mask is-star-2 flex h-12 w-12 items-center justify-center bg-error/10 dark:bg-error">
                  <i
                    className="fa fa-user-secret fa-lg text-warning"
                    aria-hidden="true"
                  />
                </div>
                <div className="font-inter">
                  <p className="text-base font-semibold text-slate-700 dark:text-navy-100">
                    {wallet.data.user_wallet.onhold_formatted}
                  </p>
                  <p>{t("onhold")}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <hr />
        <div>
          <div className="flex items-center justify-end search-header mb-3 mt-3">
            <div className="settlement-header-info-sec">
              <p>{t("total_wallet_payments")}</p>
              <span className="transaction-total">{transaction.data.total_payments}</span>
            </div>
            <div className="select-dates">
              {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
              <DatePicker
                value={filterData.from_date}
                onChange={(date) => {
                  handleFromDateChange(date)
                  setFilterData({
                    ...filterData,
                    from_date: date
                  })
                }}
                className="floating-inputs"
                format={"YYYY-MM-DD"}
                maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                editable={false}
                placeholder={t("select_from_date")}
                onOpenPickNewDate={false}
              />
              <div>
                <DatePicker
                  value={filterData.to_date}
                  onChange={(date) => {
                    handleToDateChange(date)
                    setFilterData({
                      ...filterData,
                      to_date: date
                    })
                  }}
                  className="floating-inputs"
                  format={"YYYY-MM-DD"}
                  maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                  editable={false}
                  placeholder={t("select_to_date")}
                  onOpenPickNewDate={false}
                />
              </div>
            </div>
            <Form className="exchange-form">
              <Form.Control
                className="min-width-search-input form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                name="search_key"
                placeholder={t("search_placeholder")}
                value={filterData.search_key}
                onChange={(event) =>
                  setFilterData({
                    ...filterData,
                    search_key: event.currentTarget.value,
                  })
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSubmit();
                  }
                }}
              />
            </Form>
            {/* <Select
              className="fillert-drop"
              options={options}
              isClearable
              styles={customStyles}
              placeholder={
                <div className="placeholder-flex">{t("select_status")}</div>
              }
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilterData({
                    ...filterData,
                    status: selectedOption.value,
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    status: "",
                  });
                }
              }}
            /> */}
            <div>
              <Button
                type="submit"
                disabled={error || !filterData.from_date && filterData.to_date}
                onClick={handleSubmit}
                className="search-btn btn h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
              >
                <i className="fa-solid fa-search" />
              </Button>
              <Link
                to="#"
                onClick={() => {
                  setError("")
                  setFilterData({
                    from_date: "",
                    to_date: "",
                    search_key: "",
                    // status: "",
                  });
                  dispatch(fetchAllTransactionStart());
                }}
                className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
              >
                <i className="fa-solid fa-remove" />
              </Link>
            </div>
            <div className="space-x-2">
              <Button
                onClick={exportTransctions}
                className="btn space-x-2 btn rounded-lg bg-success deposit-btn"
              >
                <span>{t("export")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4.5 w-4.5 text-dark dark:text-navy-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  width="24"
                  height="24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                  />
                </svg>
              </Button>
            </div>
          </div>
          <h2 className="pb-4 pt-4 text-lg font-medium tracking-wide text-slate-700 line-clamp-1 dark:text-navy-100">
            {t("view_user_wallet_payments")}
          </h2>
        </div>
        <div className="card mt-3">
          <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
            {transaction.loading ? (
              <Skeleton count={4} height={80} />
            ) : Object.keys(transaction.data).length > 0 &&
              transaction.data.user_wallet_payments.length > 0 ?
              <React.Fragment>
                <Table className="is-hoverable w-full text-left">
                  <thead>
                    <tr>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        {t("payment_id")}
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        {t("curency_type")}
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        {t("amount")}
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        {t("paid_date")}
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        {t("created_at")}
                      </th>
                      <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                        {t("status")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction.data.user_wallet_payments.map((payment) => (
                      <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          {payment.payment_id}
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          {payment.currency_type}
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          <p className="text-success">
                            {" "}
                            {payment.amount_formatted}
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          {payment.updated_at}
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          {payment.created_at}
                        </td>
                        <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                          {payment.status == 1 ? (
                            <p className="paid">{payment.status_formatted}</p>
                          ) : (
                            <p className="processing">
                              {payment.status_formatted}
                            </p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {transaction.data.total_payments > 12 ?
                  <div className="new-billings-pagination-sec">
                    <Pagination
                      page={page}
                      pageCount={Math.ceil(transaction.data.total_payments / 12)}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                  :
                  null}
              </React.Fragment>
              :
              <NoDataFound />
            }
          </div>
          <div className="flex flex-col justify-end space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
            <ol className="pagination space-x-1.5">
              <li> </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewWallet;
