import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { ButtonLoader } from '../Helper/Loader';
import { viewAPIKeyStart, generateAPIKeyStart, updateProfileStart, viewAPIKeyFailure } from '../../store/slices/AdminSlice';
import { getSuccessNotificationMessage } from '../Helper/NotificationMessage';
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { allCountries } from "country-telephone-data";
import { useTranslation } from 'react-multi-lang';
import { Button, Image } from 'react-bootstrap';

const BasicProfileTab = ({ profile, activeTab }) => {
  const dispatch = useDispatch();
  const t = useTranslation("profile");
  const generateAPIKey = useSelector(state => state.admin.generateAPIKey);
  const viewAPIKey = useSelector(state => state.admin.viewAPIKey);
  const updateProfile = useSelector(state => state.admin.updateProfile);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [previewImage, setPreviewImage] = useState(profile.picture);
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required")).test('len', t('name.invalid'), val => val && val.toString().length < 255),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    about: Yup.string().test('len', t('about.invalid'), val => !val ? true : val.toString().length < 255),
    residence: Yup.string().required(t("residence.required")),
    citizenship: Yup.string().required(t("citizenship.required")),
    mobile: Yup.string()
      .test("valid-mobile", (t("mobile_number.invalid")), (value) => {
        if (!value) {
          return false;
        }
        return isValidPhoneNumber(value);
      })
      .required(("Mobile required"))
      .matches(/^(?=.*[0-9])/, (t("mobile_number.invalid"))),
  });

  useEffect(() => {
    if (activeTab !== "profile") reset();
  }, [activeTab]);

  useEffect(() => {
    let objectUrl;
    if (image) {
      objectUrl = URL.createObjectURL(image);
      setPreviewImage(objectUrl);
    }
    return () => URL.revokeObjectURL(objectUrl)
  }, [image]);

  const getAPIKey = () => dispatch(profile.api_key_generated ? viewAPIKeyStart({ password }) : generateAPIKeyStart({ password }));

  const reset = () => {
    if (formRef.current)
      formRef.current.resetForm();
    setImage("");
    setPreviewImage(profile.picture);
    setShowConfirmation(false);
    dispatch(viewAPIKeyFailure());
  }

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", "Invalid");
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", "Invalid");
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const handleSubmit = values => {
    if (image) values = { ...values, picture: image };
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      values = { ...values, mobile: mobile, country_code: countryCode }
    }
    dispatch(updateProfileStart(values));
  }

  function getCountryCode(mobileCode) {
    const countryData = allCountries.find(country => country.dialCode === mobileCode);
    return countryData ? countryData.iso2.toUpperCase() : null;
  }

  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-4 sm:gap-5 lg:gap-6">
          <div className="col-span-12 lg:col-span-12">
            <Formik
              initialValues={{
                name: profile.name,
                email: profile.email,
                about: profile.about,
                mobile: `+${profile.country_code}${profile.mobile}`,
                country_code: profile.country_code,
                citizenship: profile.citizenship,
                residence: profile.residence,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              innerRef={formRef}
            >
              {({ values, setFieldValue, touched, errors }) =>
                <Form className="form">
                  <div className="">
                    <div className="flex flex-col">
                      <div className="p-4 sm:p-5">
                        <div className="space-y-5">
                          <div className="flex flex-row gap-6">
                            <div className="w-1/2">
                              <div>
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">{t("name.label")} *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder={t("name.placeholder")}
                                    name="name"
                                  />
                                </label>
                                <div className="flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="name"
                                    className="errorMsg w-100 text-tiny+"
                                  />
                                </div>
                              </div>
                              <div className="mt-3">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">{t("residence.label")} *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder={t("residence.placeholder")}
                                    name="residence"
                                  />
                                </label>
                                <div className="flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="residence"
                                    className="errorMsg w-100 text-tiny+"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <div>
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">{t("email.label")} *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder={t("email.placeholder")}
                                    name="email"
                                  />
                                </label>
                                <span className="text-tiny+ text-slate-400 dark:text-navy-300 flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="email"
                                    className="errorMsg w-100"
                                  />
                                </span>
                              </div>
                              <div className="mt-3">
                                <label className="block">
                                  <span className="font-medium text-slate-600 dark:text-navy-100">{t("citizenship.label")} *</span>
                                  <Field
                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                    placeholder={t("citizenship.placeholder")}
                                    name="citizenship"
                                  />
                                </label>
                                <div className="flex justify-between">
                                  <ErrorMessage
                                    component={"div"}
                                    name="citizenship"
                                    className="errorMsg w-100 text-tiny+"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row gap-6">
                            <div className="w-1/2">
                              <div className="block">
                                <div className="font-medium text-slate-600 dark:text-navy-100"> {t("picture.label")}</div>
                                <div className='mt-1.5'>
                                  <label className="btn relative bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90">
                                    <input
                                      tabIndex="-1"
                                      type="file"
                                      className="pointer-events-none absolute inset-0 h-full w-full opacity-0"
                                      accept=".png, .jpg, .jpeg"
                                      onChange={e => setImage(e.target.files[0])}
                                    />
                                    <div className="flex items-center space-x-2">
                                      <i className="fa-solid fa-cloud-arrow-up text-base"></i>
                                      <span>{t("picture.choose_file")}</span>
                                    </div>
                                  </label>
                                </div>
                                <div className="my-3 avatar h-24 w-24">
                                  <Image
                                    className="rounded-lg"
                                    src={previewImage}
                                    alt="avatar"
                                  />
                                </div>
                                <div>
                                </div>
                              </div>
                            </div>
                            <div className="w-1/2">
                              <div className='profile-tab'>
                                <label className="block" htmlFor='mobile'>
                                  <span className="font-medium text-slate-600 dark:text-navy-100">{t("mobile_number.label")} *</span>
                                  <PhoneInput
                                    name="mobile"
                                    defaultCountry={getCountryCode(values.country_code)}
                                    placeholder={t("mobile_number.placeholder")}
                                    value={values.mobile}
                                    onChange={(value) =>
                                      setFieldValue("mobile", value)
                                    }
                                    onBlur={() => formRef.current.submitForm()}
                                    international
                                    className={`form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent ${touched.mobile && errors.mobile
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                  />
                                  <span className="text-tiny+ text-slate-400 dark:text-navy-300 flex justify-between">
                                    <ErrorMessage
                                      component={"div"}
                                      name="mobile"
                                      className="errorMsg w-100"
                                    />
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label className="block">
                              <span>{t("about.label")}</span>
                              <Field
                                as="textarea"
                                rows={5}
                                placeholder={t("about.placeholder")}
                                className="form-control height-auto form-textarea mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent p-2.5 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                name="about"
                              />
                            </label>
                            <div className="flex justify-between">
                              <span className="text-tiny+ text-slate-400 dark:text-navy-300">
                                {t("about.max")} : 255
                              </span>
                              <ErrorMessage
                                component={"div"}
                                name="about"
                                className="errorMsg w-100 text-tiny+"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-2">
                    <Button
                      type="button"
                      className="btn min-w-[7rem] rounded-lg border font-medium text-slate-700 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-100 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                      onClick={() => reset()}>
                      {t("cancel")}
                    </Button>
                    <Button
                      type="submit"
                      className="btn min-w-[7rem] rounded-lg bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                      disabled={updateProfile.buttonDisable}
                    >
                      {updateProfile.buttonDisable ? <ButtonLoader varient="info" size={5} /> : t("submit")}
                    </Button>
                  </div>
                </Form>
              }
            </Formik>
          </div>
        </div>
      </div>
      {/* {showConfirmation ?
        <PasswordModal />
        : null
      } */}
    </>
  );
}

export default BasicProfileTab;