import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import { profileStart, setTheme } from "../../../store/slices/AdminSlice";
import {
  setLanguage,
  useTranslation
} from "react-multi-lang";
import Select from "react-select";


const AdminHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const t = useTranslation("header");
  const theme = useSelector((state) => state.admin.theme);
  const profile = useSelector((state) => state.admin.profile);
  const [showUser, setShowUser] = useState(false);
  const [userCredentailsModal, setUserCredentialsModal] = useState(false);

  const handleChange = (selectedOption) => {
    setLanguage(selectedOption.value)
    localStorage.setItem('lang', selectedOption.value);
  };

  const options = [
    { value: "en", label: t("en") },
    { value: "jpy", label: t("jpy") },
  ];

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #e47c3f!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "35px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#e47c3f",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#e47c3f",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "800",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#e47c3f!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#e47c3f",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        "&:hover": {
          backgroundColor: "#f7f7f7",
          color: "#000",
        },
      };
    },
  };


  return (
    <nav className="header print:hidden">
      {/* App Header  */}
      <div className="header-container relative flex w-full bg-white dark:bg-navy-700 print:hidden">
        {/* Header Items */}
        <div className="flex w-full items-center justify-between">
          {/* Left: Sidebar Toggle Button */}
          <div className="h-7 w-7">
            <Button
              className="sidebar-toggle ml-0.5 flex h-7 w-7 flex-col justify-center space-y-1.5 text-primary outline-none focus:outline-none dark:text-accent-light/80"
              onClick={() => {
                document.body.classList.toggle("is-sidebar-open");
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </Button>
          </div>

          {/* Right: Header buttons */}
          <div className="-mr-1.5 flex items-center space-x-2">
            <Select
              options={options}
              styles={customStyles}
              isSearchable={false}
              value={options.find(opt => opt.value === localStorage.getItem("lang"))}
              onChange={handleChange}
            />
            <Button
              type="button"
              className="btn space-x-2 bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90"
              onClick={() => navigate("/deposit")}
            >
              {t("deposit_amount")}
            </Button>

            {/* Dark Mode Toggle */}
            <button
              className="darkmode-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              onClick={() => dispatch(setTheme(theme === "dark" ? "" : "dark"))}
            >
              <svg
                className="darkmode-moon h-6 w-6 text-amber-400"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M11.75 3.412a.818.818 0 01-.07.917 6.332 6.332 0 00-1.4 3.971c0 3.564 2.98 6.494 6.706 6.494a6.86 6.86 0 002.856-.617.818.818 0 011.1 1.047C19.593 18.614 16.218 21 12.283 21 7.18 21 3 16.973 3 11.956c0-4.563 3.46-8.31 7.925-8.948a.818.818 0 01.826.404z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="darkmode-sun h-6 w-6 text-amber-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {/* Monochrome Mode Toggle */}
            <button
              className="monochrome-toggle btn h-8 w-8 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
              onClick={() => {
                document.body.classList.toggle("is-monochrome");
              }}
            >
              <i className="fa-solid fa-palette bg-gradient-to-r from-sky-400 to-blue-600 bg-clip-text text-lg font-semibold text-transparent"></i>
            </button>

            {/* Profile */}
            {!profile.loading && Object.keys(profile.data).length > 0 ? (
              <div id="profile-wrapper" className="flex">
                <button
                  id="profile-ref"
                  className={`avatar h-12 w-12 ${showUser ? "active" : ""}`}
                  onClick={() => setShowUser(!showUser)}
                  onBlur={() => {
                    setShowUser(false);
                  }}
                >
                  <img
                    className="rounded-full"
                    src={profile.data.picture}
                    alt="avatar"
                  />
                  <span className="absolute right-0 h-3.5 w-3.5 rounded-full border-2 border-white bg-success dark:border-navy-700"></span>
                </button>
                <div
                  id="profile-box"
                  className={`popper-root fixed ${showUser ? "show" : ""}`}
                  data-boundary="viewport"
                  style={{
                    position: "absolute",
                    inset: "0px 0px auto auto",
                    margin: "0px",
                    transform: "translate(-136px, 10px)",
                  }}
                  data-popper-placement="left-start"
                >
                  <div className="popper-box w-64 rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-600 dark:bg-navy-700">
                    <div className="flex items-center space-x-4 rounded-t-lg bg-slate-100 py-5 px-4 dark:bg-navy-800">
                      <div className="avatar h-14 w-14">
                        <img
                          className="rounded-full"
                          src={profile.data.picture}
                          alt="avatar"
                        />
                      </div>
                      <div>
                        <Link
                          to="#"
                          className="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light dark:focus:text-accent-light"
                        >
                          {profile.data.name}
                        </Link>
                        <p className="text-xs text-slate-400 dark:text-navy-300">
                          {profile.data.email}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col pt-2 pb-5">
                      <Link
                        to="/profile"
                        className="group flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all hover:bg-slate-100 focus:bg-slate-100 dark:hover:bg-navy-600 dark:focus:bg-navy-600"
                      >
                        <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-warning text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4.5 w-4.5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            ></path>
                          </svg>
                        </div>

                        <div>
                          <h2 className="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                            {t("profile")}
                          </h2>
                          <div className="text-xs text-slate-400 line-clamp-1 dark:text-navy-300">
                            {t("your_profile_setting")}
                          </div>
                        </div>
                      </Link>
                      <div className="mt-3 px-4">
                        <button
                          onClick={() => logout()}
                          className="btn h-9 w-full space-x-2 bg-primary text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            ></path>
                          </svg>
                          <span>{t("logout")}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {userCredentailsModal && (
        <div
          class="fixed inset-0 z-[100] flex flex-col items-center justify-center overflow-hidden px-4 py-6 sm:px-5"
          role="dialog"
        >
          <div
            class="modal-overlay absolute inset-0 bg-slate-900/60 backdrop-blur"
            onClick={() => setUserCredentialsModal(!userCredentailsModal)}
          ></div>
          <div class="modal-content scrollbar-sm relative flex max-w-lg flex-col overflow-y-auto rounded-lg bg-white text-center dark:bg-navy-700">
            <div class="flex justify-center  items-center space-x-4 rounded-t-lg bg-slate-100 py-5 px-4 dark:bg-navy-800">
              <div>
                <p class="text-base font-medium text-slate-700 hover:text-primary focus:text-primary dark:text-navy-100 dark:hover:text-accent-light text-center dark:focus:text-accent-light">
                  {t("user_credentails")}
                </p>
              </div>
            </div>
            <div class="flex flex-col pt-2 pb-5">
              <p class="flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all">
                <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-warning text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4.5 w-4.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    ></path>
                  </svg>
                </div>
                <div>
                  <h2 class="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                    {t("login_username")} :{" "}
                    {profile.data.login_username
                      ? profile.data.login_username
                      : t("n_a")}
                  </h2>
                </div>
              </p>
              <p class="flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all">
                <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-warning text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4.5 w-4.5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </div>
                <div>
                  <h2 class="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                    {t("login_password")} :{" "}
                    {profile.data.login_password
                      ? profile.data.login_password
                      : t("n_a")}
                  </h2>
                </div>
              </p>
              <p class="flex items-center space-x-3 py-2 px-4 tracking-wide outline-none transition-all">
                <div class="flex h-8 w-8 items-center justify-center rounded-lg bg-warning text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4.5 w-4.5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>
                </div>
                <div>
                  <h2 class="font-medium text-slate-700 transition-colors group-hover:text-primary group-focus:text-primary dark:text-navy-100 dark:group-hover:text-accent-light dark:group-focus:text-accent-light">
                    {t("login_url")} :{" "}
                    <a
                      className="hover:text-primary"
                      href={profile.data.login_url}
                      target="_blank"
                    >
                      {profile.data.login_url ? profile.data.login_url : "N/A"}
                    </a>
                  </h2>
                </div>
              </p>
              {profile.data.login_password && (
                <div class="alert flex overflow-hidden rounded-lg bg-warning/10 text-warning dark:bg-warning/15 mt-5">
                  <div class="flex flex-1 items-center space-x-3 p-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
                    </svg>
                    <div class="flex-1"><b>{t("note")}:</b> {t("please_update_para")}</div>
                  </div>
                  <div class="w-1.5 bg-warning"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default AdminHeader;