import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    depositToken: getCommonInitialState(),
    tokenDepositList: getCommonInitialState({ payments: [], total: 0 }),
    singleDepositToken: getCommonInitialState(),
    walletData: getCommonInitialState(),
    allTransaction: getCommonInitialState({ user_wallet_payments: [], total_payments: 0 }),
    singleWallet: getCommonInitialState(),
    cancelDepositToken: getCommonInitialState(),
    userDepositsExport: getCommonInitialState(),
    userWalletsExport: getCommonInitialState(),
};

const SubscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {

        //Deposit
        depositTokenStart: (state, action) => { state.depositToken = getCommonStart() },
        depositTokenSuccess: (state, action) => { state.depositToken = getCommonSuccess(action.payload) },
        depositTokenFailure: (state, action) => { state.depositToken = getCommonFailure(action.payload) },

        //Cancel Deposit
        cancelDepositTokenStart: (state, action) => { state.cancelDepositToken = getCommonStart(action.payload) },
        cancelDepositTokenSuccess: (state, action) => { state.cancelDepositToken = getCommonSuccess(action.payload) },
        cancelDepositTokenFailure: (state, action) => { state.cancelDepositToken = getCommonFailure(action.payload) },

        //Deposit Token Lists
        tokenDepositListStart: (state) => { state.tokenDepositList = getCommonStart({ payments: [], total: 0 }) },
        tokenDepositListSuccess: (state, action) => { state.tokenDepositList = getCommonSuccess(action.payload) },
        tokenDepositListFailure: (state, action) => { state.tokenDepositList = getCommonFailure(action.payload) },
        moreTokenDepositListStart: (state, action) => { state.tokenDepositList = state.tokenDepositList },

        //Single Deposit Token
        singleDepositTokenStart: (state, action) => { state.singleDepositToken = getCommonStart() },
        singleDepositTokenSuccess: (state, action) => { state.singleDepositToken = getCommonSuccess(action.payload) },
        singleDepositTokenFailure: (state, action) => { state.singleDepositToken = getCommonFailure(action.payload) },

        //Wallet Lists
        fetchWalletDetailsStart: (state, action) => { state.walletData = getCommonStart() },
        fetchWalletDetailsSuccess: (state, action) => { state.walletData = getCommonSuccess(action.payload) },
        fetchWalletDetailsFailure: (state, action) => { state.walletData = getCommonFailure(action.payload) },

        //Wallet Transactions
        fetchAllTransactionStart: (state) => { state.allTransaction = getCommonStart({ user_wallet_payments: [], total_payments: 0 }) },
        fetchAllTransactionSuccess: (state, action) => { state.allTransaction = getCommonSuccess(action.payload) },
        fetchAllTransactionFailure: (state, action) => { state.allTransaction = getCommonFailure(action.payload) },
        fetchMoreTransactionStart: (state, action) => { state.allTransaction = state.allTransaction },

        //Single Wallet
        fetchSingleWalletViewStart: (state, action) => { state.singleWallet = getCommonStart() },
        fetchSingleWalletViewSuccess: (state, action) => { state.singleWallet = getCommonSuccess(action.payload) },
        fetchSingleWalletViewFailure: (state, action) => { state.singleWallet = getCommonFailure(action.payload) },

        //User Deposit Export
        userDepositsExportStart: (state, action) => { state.userDepositsExport = getCommonStart() },
        userDepositsExportSuccess: (state, action) => { state.userDepositsExport = getCommonSuccess(action.payload) },
        userDepositsExportFailure: (state, action) => { state.userDepositsExport = getCommonFailure(action.payload) },

        //User Wallets Export
        userWalletsExportStart: (state, action) => { state.userWalletsExport = getCommonStart() },
        userWalletsExportSuccess: (state, action) => { state.userWalletsExport = getCommonSuccess(action.payload) },
        userWalletsExportFailure: (state, action) => { state.userWalletsExport = getCommonFailure(action.payload) },

    }
});


export const {
    depositTokenStart,
    depositTokenSuccess,
    depositTokenFailure,
    tokenDepositListStart,
    tokenDepositListSuccess,
    tokenDepositListFailure,
    moreTokenDepositListStart,
    singleDepositTokenStart,
    singleDepositTokenSuccess,
    singleDepositTokenFailure,
    fetchWalletDetailsStart,
    fetchWalletDetailsSuccess,
    fetchWalletDetailsFailure,
    fetchAllTransactionStart,
    fetchAllTransactionSuccess,
    fetchAllTransactionFailure,
    fetchMoreTransactionStart,
    fetchSingleWalletViewStart,
    fetchSingleWalletViewSuccess,
    fetchSingleWalletViewFailure,
    cancelDepositTokenStart,
    cancelDepositTokenSuccess,
    cancelDepositTokenFailure,
    userDepositsExportStart,
    userDepositsExportSuccess,
    userDepositsExportFailure,
    userWalletsExportStart,
    userWalletsExportSuccess,
    userWalletsExportFailure,

} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;