import React from 'react';
import { useDispatch } from 'react-redux';
import {
    profileFailure,
} from '../store/slices/AdminSlice';

const useLogout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(profileFailure());
        
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
    }

    return { logout };

}

export default useLogout;