import React, { useState, useEffect } from "react";
import { Form, InputGroup, Table, Button, Image } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import {
  tokenDepositListStart,
  cancelDepositTokenStart,
  userDepositsExportStart
} from "../../store/slices/SubscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Pagination from "../Helper/Pagination";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const DepositLists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("deposit_list");
  const [searchParams] = useSearchParams();
  const [skipRender, setSkipRender] = useState(true);
  const [error, setError] = useState(false);
  const tokenDepositList = useSelector((state) => state.subscription.tokenDepositList);
  const cancelDepositToken = useSelector((state) => state.subscription.cancelDepositToken);
  const userDepositsExport = useSelector((state) => state.subscription.userDepositsExport);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [filterData, setFilterData] = useState({
    from_date: "",
    to_date: "",
    search_key: "",
    status:  "",
  });

  const options = [
    { value: "1", label: t("processing") },
    { value: "2", label: t("verified") },
    { value: "3", label: t("declined") },
    { value: "4", label: t("expired") },
    { value: "6", label: t("cancelled") },
  ];

  useEffect(() => {
    dispatch(
      tokenDepositListStart({
        ...filterData,
        status: filterData.status ? filterData.status.value : "",
        skip: 12 * (page - 1),
        take: 12,
      })
    );
  }, [page]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
    setSkipRender(false);
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    navigate(`/user-deposits?page=${selected + 1}`);
  };


  const handleSearch = () => {
    dispatch(
      tokenDepositListStart({
        ...filterData,
        status: filterData.status ? filterData.status.value : "",
        skip: 0,
        take: 12,
      })
    );
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0.5rem",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "0.5rem!important",
      boxShadow: "none!important",
      height: "40px",
      minWidth: "150px",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#32089F",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#32089F!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#32089F!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 2:
        return "payment-verified";
      case 3:
        return "payment-declined";
      case 4:
        return "payment-expired";
      case 6:
        return "payment-cancelled";
      default:
        return "payment-processed";
    }
  };

  const handleToDateChange = (date) => {
    if (date && filterData.from_date && dayjs(date).isBefore(filterData.from_date, 'day')) {
      setError(t("from_date_val_error"));
    } else {
      setError('');
    }
  };

  const exportTransctions = () => {
    dispatch(userDepositsExportStart({
      ...filterData,
      status: filterData.status ? filterData.status.value : "",
    }));
  }

  useEffect(() => {
    if (!skipRender && !userDepositsExport.loading &&
      Object.keys(userDepositsExport.data).length > 0)
      window.open(userDepositsExport.data.url, '_blank');
    setSkipRender(false);
  }, [userDepositsExport]);


  const handleFromDateChange = (date) => {
    if (date && filterData.to_date &&
      dayjs(date).isAfter(filterData.to_date, 'day')) {
      setError(t("from_date_val_error"));
    } else {
      setError('');
    }
  };


  return (
    <div>
      <div className="flex items-center justify-between py-5 lg:py-6">
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-medium text-slate-800 dark:text-navy-50 lg:text-2xl">
            {" "}
            {t("heading")}{" "}
          </h2>
          <div className="hidden h-full py-1 sm:flex">
            <div className="h-full w-px bg-slate-300 dark:bg-navy-600">
              &nbsp;
            </div>
          </div>
          <ul className="hidden flex-wrap items-center space-x-2 sm:flex">
            <li className="flex items-center space-x-2">
              <Link
                to="/user-deposits"
                className="text-primary transition-colors hover:text-primary-focus
                 dark:text-accent-light dark:hover:text-accent"
              >
                {t("heading")}{" "}
              </Link>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </li>
            <li>{t("view_deposits")} </li>
          </ul>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:gap-5 lg:gap-6">
        <div>
          <div className="flex items-center justify-end search-header">
            <div className="settlement-header-info-sec">
              <p>{t("total_deposits")}</p>
              <span className="transaction-total">{tokenDepositList.data.total}</span>
            </div>
            <div className="select-dates">
              {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
              <DatePicker
                value={filterData.from_date}
                onChange={(date) => {
                  handleFromDateChange(date)
                  setFilterData({
                    ...filterData,
                    from_date: date
                  })
                }}
                className="floating-inputs"
                format={"YYYY-MM-DD"}
                maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                editable={false}
                placeholder={t("select_from_date")}
                onOpenPickNewDate={false}
              />
              <div>
                <DatePicker
                  value={filterData.to_date}
                  onChange={(date) => {
                    handleToDateChange(date)
                    setFilterData({
                      ...filterData,
                      to_date: date
                    })
                  }}
                  className="floating-inputs"
                  format={"YYYY-MM-DD"}
                  maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                  editable={false}
                  placeholder={t("select_to_date")}
                  onOpenPickNewDate={false}
                />
              </div>
            </div>
            <Form className="exchange-form">
              <Form.Control
                className="min-width-search-input form-input w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                name="search_key"
                placeholder={t("search_placeholder")}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSearch();
                  }
                }}
                value={filterData.search_key}
                onChange={(event) =>
                  setFilterData({
                    ...filterData,
                    search_key: event.target.value,
                  })
                }
              />
            </Form>
            <Select
              className="fillert-drop"
              options={options}
              isClearable
              styles={customStyles}
              value={filterData.status}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setFilterData({
                    ...filterData,
                    status: selectedOption,
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    search_key: "",
                    status: "",
                  });
                }
              }}
              placeholder={
                <div className="placeholder-flex">{t("select_status")}</div>
              }
            />
            <div>
              <Button
                type="submit"
                disabled={error || !filterData.from_date && filterData.to_date}
                onClick={handleSearch}
                className="search-btn btn h-9 w-9 rounded-full bg-success p-0 font-medium text-white hover:bg-success-focus hover:shadow-lg hover:shadow-success/50 focus:bg-success-focus active:bg-success-focus/90"
              >
                <i className="fa-solid fa-search" />
              </Button>
              <Link
                to="#"
                onClick={() => {
                  setError("")
                  setPage(1)
                  setFilterData({
                    from_date: "",
                    to_date: "",
                    search_key: "",
                    status: "",
                  });
                  dispatch(tokenDepositListStart());
                }}
                className="btn h-9 w-9 rounded-full bg-error p-0 font-medium text-white hover:bg-error-focus hover:shadow-lg hover:shadow-error/50 focus:bg-error-focus active:bg-error-focus/90"
              >
                <i className="fa-solid fa-remove" />
              </Link>
            </div>
            <div className="space-x-2">
              <Button
                onClick={exportTransctions}
                className="btn space-x-2 btn rounded-lg bg-success deposit-btn"
              >
                <span>{t("export")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4.5 w-4.5 text-dark dark:text-navy-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  width="24"
                  height="24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
                  />
                </svg>
              </Button>
            </div>
          </div>
          <div className="card mt-3">
            <div className="is-scrollbar-hidden min-w-full overflow-x-auto">
              {tokenDepositList.loading ? (
                <Skeleton count={4} height={80} />
              ) : Object.keys(tokenDepositList.data).length > 0 &&
                tokenDepositList.data.payments.length > 0 ?
                <React.Fragment>
                  <Table className="is-hoverable w-full text-left mb-4">
                    <thead>
                      <tr>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("s_no")}
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("reference_id")}
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("account_number")}
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("amount")}
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("verification_code")}
                        </th>
                        {searchParams.get("status") != 2 ?
                          <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                            {t("expiry_date")}
                          </th>
                          :
                          null
                        }
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("status")}
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("requested_date")}
                        </th>
                        <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold uppercase text-slate-800 dark:bg-navy-800 dark:text-navy-100 lg:px-5">
                          {t("action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tokenDepositList.data.payments.map((payment, i) => (
                        <tr className="border-y border-transparent border-b-slate-200 dark:border-b-navy-500">
                          <td className="whitespace-nowrap px-3 py-3 font-medium  text-slate-700 dark:text-navy-100 lg:px-4">
                            {(12 * (page - 1)) + (i + 1)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 font-medium  text-slate-700 dark:text-navy-100 lg:px-4">
                            {payment.unique_id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                            {payment.billing_account.account_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 font-medium  text-slate-700 dark:text-navy-100 lg:px-4">
                            {payment.amount_type}
                            {payment.amount_formatted}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-5">
                            <span className="text-primary">
                              {payment.verification_code}
                            </span>
                          </td>
                          {searchParams.get("status") != 2 ?
                            < td className="whitespace-nowrap px-3 py-3 font-medium  text-slate-700 dark:text-navy-100 lg:px-4">
                              {payment.expiry_date}
                            </td>
                            :
                            null}
                          <td className="whitespace-nowrap px-3 py-3 font-medium  text-slate-700 dark:text-navy-100 lg:px-4">
                            <div className={getStatusClass(payment.status)}>
                              {payment.status_formatted}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-4">
                            {payment.created_at}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 font-medium text-slate-700 dark:text-navy-100 lg:px-4">
                            {payment.status == 1 ?
                              <Button
                                className="efi-cancel-btn"
                                disabled={cancelDepositToken.buttonDisable}
                                onClick={() => dispatch(cancelDepositTokenStart({
                                  user_deposit_id: payment.id
                                }))}
                              >
                                {(cancelDepositToken.buttonDisable && cancelDepositToken.data.user_deposit_id == payment.id) ? t("loading") : t("cancel")}
                              </Button>
                              :
                              <p> - </p>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {tokenDepositList.data.total > 12 ?
                    <div className="new-billings-pagination-sec">
                      <Pagination
                        page={page}
                        pageCount={Math.ceil(tokenDepositList.data.total / 12)}
                        handlePageClick={handlePageClick}
                      />
                    </div>
                    :
                    null}
                </React.Fragment>
                :
                <NoDataFound />
              }
            </div>
            <div className="flex flex-col justify-end space-y-4 px-4 py-4 sm:flex-row sm:items-center sm:space-y-0 sm:px-5">
              <ol className="pagination space-x-1.5">
                <li> </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default DepositLists;
