import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../store/slices/AdminSlice';
import {
  setTranslations,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import jpy from "../translations/jpy.json";


// Layouts
import { AuthLayout, EmptyLayout, AdminLayout } from '../Layout';
// Pages
import Page404 from '../Helper/Page404';
import ProfileIndex from '../Profile/ProfileIndex';
import LoginIndex from '../Auth/LoginIndex';
import RegisterIndex from '../Auth/RegisterIndex';
import ForgotPasswordIndex from '../Auth/ForgotPasswordIndex';
import ResetPasswordIndex from "../Auth/ResetPasswordIndex";
import DashboardIndex from '../Dashboard/DashboardIndex';
import DepositLists from '../Deposit/DepositLists';
import ViewWallet from '../Wallet/ViewWallet';
import DepositAmount from '../Deposit/DepositAmount';
import DepositBankAccount from '../Deposit/DepositBankAccount';

setTranslations({ en, es,jpy });

const CommonRoute = ({
  component: Component,
  authLayout: AuthLayout,
  adminLayout: AdminLayout,
  ...rest
}) => localStorage.getItem("userId") ?
    <AdminLayout {...rest}>
      <Component />
    </AdminLayout>
    : <AuthLayout {...rest}>
      <Component />
    </AuthLayout>

const AppRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) =>localStorage.getItem("userId")?
    <Navigate to={{ pathname: "/" }} />
    : <Layout {...rest}>
      <Component />
    </Layout>;

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => localStorage.getItem("userId")  ?
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
    : <Navigate to={{ pathname: "/login" }} />;

const Base = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.admin.theme);
  const profile = useSelector(state => state.admin.profile);
  const settingsJson = useSelector((state) => state.admin.settingsJson);

  const [, setState] = useState();

  useEffect(() => {
    if (localStorage.getItem("dark-mode"))
      dispatch(setTheme(localStorage.getItem("dark-mode")));
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("dark-mode", theme)
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.removeItem("dark-mode");
    }
  }, [theme]);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "jpy"
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    setState(1);
  }, [profile]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/login"}
            element={
              <AppRoute
                component={LoginIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute
                component={RegisterIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute
                component={ForgotPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/reset-password/:token"}
            element={
              <AppRoute
                component={ResetPasswordIndex}
                layout={AuthLayout}
              />
            }
          />
          <Route
            path={"/"}
            element={
              <PrivateRoute
                component={DashboardIndex}
                layout={AdminLayout}
              />
            }
          />

          <Route
            path={"/profile"}
            element={
              <PrivateRoute
                component={ProfileIndex}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/deposit"}
            element={
              <PrivateRoute
                component={DepositAmount}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/deposit-transaction/:user_deposit_id"}
            element={
              <PrivateRoute
                component={DepositBankAccount}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/user-deposits"}
            element={
              <PrivateRoute
                component={DepositLists}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/user-wallet"}
            element={
              <PrivateRoute
                component={ViewWallet}
                layout={AdminLayout}
              />
            }
          />

          <Route
            path={"/*"}
            element={
              <CommonRoute
                component={Page404}
                authLayout={EmptyLayout}
                adminLayout={AdminLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Base;